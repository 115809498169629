import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_8 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_customers_export_modal"
}
const _hoisted_9 = { class: "svg-icon svg-icon-2" }
const _hoisted_10 = {
  type: "button",
  class: "btn btn-primary",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_add_customer"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = {
  key: 1,
  class: "d-flex justify-content-end align-items-center",
  "data-kt-customer-table-toolbar": "selected"
}
const _hoisted_13 = { class: "fw-bolder me-5" }
const _hoisted_14 = { class: "me-2" }
const _hoisted_15 = { class: "card-body pt-0" }
const _hoisted_16 = { class: "form-check form-check-sm form-check-custom form-check-solid" }
const _hoisted_17 = ["value"]
const _hoisted_18 = {
  href: "#",
  class: "text-gray-600 text-hover-primary mb-1"
}
const _hoisted_19 = ["src"]
const _hoisted_20 = {
  href: "#",
  class: "btn btn-sm btn-light btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_21 = { class: "svg-icon svg-icon-5 m-0" }
const _hoisted_22 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4",
  "data-kt-menu": "true"
}
const _hoisted_23 = { class: "menu-item px-3" }
const _hoisted_24 = { class: "menu-item px-3" }
const _hoisted_25 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ExportCustomerModal = _resolveComponent("ExportCustomerModal")!
  const _component_AddCustomerModal = _resolveComponent("AddCustomerModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-15",
              placeholder: "Search Customers"
            }, null, 544), [
              [_vModelText, _ctx.search]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.checkedCustomers.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("button", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
                  ]),
                  _cache[4] || (_cache[4] = _createTextVNode(" Export "))
                ]),
                _createElementVNode("button", _hoisted_10, [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotone/Navigation/Plus.svg" })
                  ]),
                  _cache[5] || (_cache[5] = _createTextVNode(" Add Customer "))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.checkedCustomers.length), 1),
                  _cache[6] || (_cache[6] = _createTextVNode("Selected "))
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-danger",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.deleteFewCustomers()))
                }, " Delete Selected ")
              ])),
          _cache[7] || (_cache[7] = _createElementVNode("div", {
            class: "d-flex justify-content-end align-items-center d-none",
            "data-kt-customer-table-toolbar": "selected"
          }, [
            _createElementVNode("div", { class: "fw-bolder me-5" }, [
              _createElementVNode("span", {
                class: "me-2",
                "data-kt-customer-table-select": "selected_count"
              }),
              _createTextVNode("Selected ")
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-danger",
              "data-kt-customer-table-select": "delete_selected"
            }, " Delete Selected ")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_Datatable, {
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true
        }, {
          "cell-checkbox": _withCtx(({ row: customer }) => [
            _createElementVNode("div", _hoisted_16, [
              _withDirectives(_createElementVNode("input", {
                class: "form-check-input",
                type: "checkbox",
                value: customer.id,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkedCustomers) = $event))
              }, null, 8, _hoisted_17), [
                [_vModelCheckbox, _ctx.checkedCustomers]
              ])
            ])
          ]),
          "cell-name": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.name), 1)
          ]),
          "cell-email": _withCtx(({ row: customer }) => [
            _createElementVNode("a", _hoisted_18, _toDisplayString(customer.email), 1)
          ]),
          "cell-company": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.company), 1)
          ]),
          "cell-paymentMethod": _withCtx(({ row: customer }) => [
            _createElementVNode("img", {
              src: customer.payment.icon,
              class: "w-35px me-3",
              alt: ""
            }, null, 8, _hoisted_19),
            _createTextVNode(_toDisplayString(customer.payment.ccnumber), 1)
          ]),
          "cell-date": _withCtx(({ row: customer }) => [
            _createTextVNode(_toDisplayString(customer.date), 1)
          ]),
          "cell-actions": _withCtx(({ row: customer }) => [
            _createElementVNode("a", _hoisted_20, [
              _cache[8] || (_cache[8] = _createTextVNode("Actions ")),
              _createElementVNode("span", _hoisted_21, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr072.svg" })
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_router_link, {
                  to: "/apps/customers/customer-details",
                  class: "menu-link px-3"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode("View")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("a", {
                  onClick: ($event: any) => (_ctx.deleteCustomer(customer.id)),
                  class: "menu-link px-3"
                }, "Delete", 8, _hoisted_25)
              ])
            ])
          ]),
          _: 1
        }, 8, ["table-data", "table-header"])
      ])
    ]),
    _createVNode(_component_ExportCustomerModal),
    _createVNode(_component_AddCustomerModal)
  ], 64))
}